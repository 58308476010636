/*
 * @name: 张梦琳
 * @test: test font
 * @Date: 2021-02-24 16:35:59
 * @FilePath: \web_bcrm\src\api\ceshi_xiugai2\myproject.js
 * @msg:
 * @param:
 * @return:
 */
import request from '@/utils/request';
//全部项目zml
export function GetAgentList(data) {
  return request({
    url: '/clientserver.php/Agent/index',
    method: 'post',
    data: data
  });
}
export function GetIdCode(data) {
  return request({
    url: '/clientserver.php/Agent/checkIdentifier',
    method: 'post',
    data: data
  });
}
//新增zml
export function addAgentList(data) {
  return request({
    url: '/clientserver.php/Agent/save',
    method: 'post',
    data: data
  });
}
//删除zml
export function delAgentList(data) {
  return request({
    url: '/clientserver.php/Agent/delete',
    method: 'post',
    data: data
  });
}
//修改zml
export function updateAgentList(data) {
  return request({
    url: '/clientserver.php/Agent/update',
    method: 'post',
    data: data
  });
}

//转移创建人zml
export function AgenttransferCreateId(data) {
  return request({
    url: '/clientserver.php/Agent/transferCreateId',
    method: 'post',
    data: data
  });
}
//置顶/取消置顶zml
export function Agenttop(data) {
  return request({
    url: '/clientserver.php/Agent/top',
    method: 'post',
    data: data
  });
}
//头像上传 、zml
export function Agentatimg(data) {
  var param = new FormData();
  param.append('file', data.file);
  param.append('type', '200011');
  return request({
    url: '/clientserver.php/Common/attachment',
    method: 'post',
    data: param
  });
}
//附件上传、zml
export function Agentatfu(data) {
  var param = new FormData();
  param.append('file', data.file);
  param.append('type', '200012');
  return request({
    url: '/clientserver.php/Common/attachment',
    method: 'post',
    data: param
  });
}
//营业执照上传zml
export function Agentatying(data) {
  var param = new FormData();
  param.append('file', data.file);
  param.append('type', '200013');
  return request({
    url: '/clientserver.php/Common/attachment',
    method: 'post',
    data: param
  });
}
//读取一条信息zml
export function Agentread(data) {
  return request({
    url: '/clientserver.php/Agent/read',
    method: 'post',
    data: data
  });
}

//获取跟进列表zml
export function AgentFollow(data) {
  return request({
    url: '/clientserver.php/AgentFollow/index',
    method: 'post',
    data: data
  });
}
//新增跟进列表zml
export function addAgentFollow(data) {
  return request({
    url: '/clientserver.php/AgentFollow/save',
    method: 'post',
    data: data
  });
}
//跟进附件附件上传、zml
export function Agentatgenjinfu(data) {
  var param = new FormData();
  param.append('file', data.file);
  param.append('type', '200014');
  return request({
    url: '/clientserver.php/Common/attachment',
    method: 'post',
    data: param
  });
}
//师资导入zml
export function AgentexportExcel(data) {
  return request({
    url: '/clientserver.php/Agent/exportExcel',
    method: 'post',
    data: data
  });
}

//客户新增记录 列表zml
export function CustomerAddLog(data) {
  return request({
    url: '/clientserver.php/CustomerAddLog/index',
    method: 'post',
    data: data
  });
}
//全部项目撞单保存
export function RepetitionAdd(data) {
  return request({
    url: '/clientserver.php/Agent/repetition_add',
    method: 'post',
    data: data
  });
}
//商城免费报名客户列表
export function customerList(data) {
  return request({
    url: '/clientserver.php/ShopFreecustomers/customerList',
    method: 'post',
    data: data
  });
}
//企业名称下拉框
export function agentList(data) {
  return request({
    url: '/clientserver.php/ShopFreecustomers/agentList',
    method: 'post',
    data: data
  });
}
//企业名称下拉框
export function checkCustomer(data) {
  return request({
    url: '/clientserver.php/CallTianRun/checkCustomer',
    method: 'post',
    data: data
  });
}
export function addCallLog(data) {
  return request({
    url: '/clientserver.php/CallTianRun/addCallLog',
    method: 'post',
    data: data
  });
}