var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-detail df" },
    [
      _c("div", { staticClass: "main-left" }, [
        _c("div", { staticClass: "photo-box bgwh tc mb20" }, [
          _c("img", {
            staticClass: "photos",
            attrs: { src: _vm.imageUrl, alt: "" },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "f18 g6" }, [_vm._v(_vm._s(_vm.info.title))]),
          _vm._v(" "),
          _c("p", { staticClass: "f18 g9" }, [_vm._v(_vm._s(_vm.info.mobile))]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-right", staticStyle: { position: "relative" } },
        [
          _c(
            "el-tabs",
            { attrs: { type: "border-card" } },
            [
              _c(
                "el-tab-pane",
                { staticClass: "tadpane", attrs: { label: "基本信息" } },
                [
                  _c(
                    "div",
                    { staticClass: "fjsb", staticStyle: { width: "100%" } },
                    [
                      _c(
                        "ul",
                        {
                          staticStyle: {
                            width: "70%",
                            "border-right": "1px dashed #eee",
                            "border-width": "1px",
                            display: "inline-block",
                          },
                        },
                        [
                          _c("li", { staticClass: "tabLists" }, [
                            _c("h4", [_vm._v("基本信息：")]),
                            _vm._v(" "),
                            _c(
                              "table",
                              {
                                staticClass: "tabstyles",
                                attrs: { border: "1", rules: "all" },
                              },
                              [
                                _c("tr", [
                                  _c("th", [_vm._v("公司名称 /个人名称：")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.info.title))]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("花名:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.flower_name)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [
                                    _vm._v("商城店铺名称 / 所属公司："),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.shop_name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("项目类别:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.leibie_name)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("签约类型:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.a_group_name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("合作级别:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.info.cooperation_level_name)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [
                                    _vm._v("纳税人识别号 / 身份证号码:"),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.identifier)),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("省市区+详细地址:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.info.area + "," + _vm.info.address
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("公司规模:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.scale_name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("公司等级:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.company_level)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("备注:")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.info.note))]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("公司logo:")]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "logo" }, [
                                    _vm.info.img_info
                                      ? _c("img", {
                                          attrs: { src: _vm.imageUrl, alt: "" },
                                        })
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "tabLists" }, [
                            _c("h4", [_vm._v("联系人信息：")]),
                            _vm._v(" "),
                            _c(
                              "table",
                              {
                                staticClass: "tabstyles",
                                attrs: { border: "1", rules: "all" },
                              },
                              [
                                _c("tr", [
                                  _c("th", [_vm._v("联系人:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.full_name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("电话号码:")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.info.mobile))]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("岗位:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.post_name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("微信:")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.info.weixin))]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("QQ:")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.info.qq))]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("邮箱:")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.info.email))]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("负责业务:")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.info.services))]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("性别:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.sex ? "女" : "男")),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("出生日期:")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.info.birthday))]),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "tabLists" }, [
                            _c("h4", [_vm._v("开票信息：")]),
                            _vm._v(" "),
                            _c(
                              "table",
                              {
                                staticClass: "tabstyles",
                                attrs: { border: "1", rules: "all" },
                              },
                              [
                                _c("tr", [
                                  _c("th", [_vm._v("开票公司名称:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.invoice_company)),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("银行账号:")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.info.card_no))]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("银行名称:")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.info.bank))]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("开户行名称:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.bank_name)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("开户行地址:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.bank_address)),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("法人:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.legal_person)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("座机:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.landline_phone)),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("营业执照附件:")]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "poi blueFont" },
                                    [
                                      _vm.info.business_license_info
                                        ? _c("el-image", {
                                            attrs: {
                                              src: _vm.imgYingye,
                                              "preview-src-list": _vm.srcList,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "tabLists" }, [
                            _c("h4", [_vm._v("账号信息：")]),
                            _vm._v(" "),
                            _c(
                              "table",
                              {
                                staticClass: "tabstyles",
                                attrs: { border: "1", rules: "all" },
                              },
                              [
                                _c("tr", [
                                  _c("th", [_vm._v("用户名:")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(_vm.info.username))]),
                                  _vm._v(" "),
                                  _c("th"),
                                  _vm._v(" "),
                                  _c("td"),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "tabLists" }, [
                            _c("h4", [_vm._v("其他信息：")]),
                            _vm._v(" "),
                            _c(
                              "table",
                              {
                                staticClass: "tabstyles",
                                attrs: { border: "1", rules: "all" },
                              },
                              [
                                _c("tr", [
                                  _c("th", [_vm._v("创建人:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.create_name)),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("创建日期:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.create_time)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("th", [_vm._v("更新人:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.updateman)),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("更新日期:")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.info.update_time)),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "w400 pl30 ble" },
                        [
                          _c(
                            "div",
                            { staticClass: "fjsb fac" },
                            [
                              _c("h4", [_vm._v("跟进记录")]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", round: "" },
                                  on: { click: _vm.NewFollowUpRecord },
                                },
                                [_vm._v("新建跟进记录")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.follows_up.length != 0
                            ? _c(
                                "el-timeline",
                                _vm._l(
                                  _vm.follows_up,
                                  function (activity, index) {
                                    return _c(
                                      "el-timeline-item",
                                      {
                                        key: index,
                                        attrs: { placement: "top" },
                                      },
                                      [
                                        _c(
                                          "el-card",
                                          {
                                            attrs: {
                                              "body-style": { padding: "15px" },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "g6 lh18" },
                                              [
                                                _c("span", [_vm._v("类型：")]),
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(activity.type) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "g6 lh18" },
                                              [
                                                _c("span", [_vm._v("主题 ：")]),
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(activity.subject) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            activity.file_info !== ""
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "g6 lh18 blueFont poi",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        附件 ：\n                                        "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "blueFont",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            activity.file_info
                                                              .file_name
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            activity.next_time
                                              ? _c(
                                                  "div",
                                                  { staticClass: "g6 lh18" },
                                                  [
                                                    _vm._v(
                                                      "下次提醒时间 ：" +
                                                        _vm._s(
                                                          activity.next_time
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _c(
                                "h3",
                                { staticStyle: { "text-align": "center" } },
                                [_vm._v("暂无跟进记录")]
                              ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.followUpDialog
        ? _c(
            "el-dialog",
            {
              attrs: { title: "跟进", visible: _vm.followUpDialog },
              on: {
                "update:visible": function ($event) {
                  _vm.followUpDialog = $event
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c("el-col", { attrs: { md: 24, lg: 12, span: 12 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "followUpForm",
                            staticStyle: { "margin-left": "50px" },
                            attrs: {
                              "label-position": "right",
                              "label-width": "120px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "名称:", prop: "subject" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入内容",
                                    disabled: true,
                                  },
                                  model: {
                                    value: this.$route.query.title,
                                    callback: function ($$v) {
                                      _vm.$set(this.$route.query, "title", $$v)
                                    },
                                    expression: "this.$route.query.title",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "is-required",
                                attrs: { label: "主题:", prop: "subject" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea", rows: 3 },
                                  model: {
                                    value: _vm.tempFollowup.subject,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.tempFollowup, "subject", $$v)
                                    },
                                    expression: "tempFollowup.subject",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "is-required",
                                attrs: { label: "类型:", prop: "type" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "100%" },
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.tempFollowup.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tempFollowup, "type", $$v)
                                      },
                                      expression: "tempFollowup.type",
                                    },
                                  },
                                  _vm._l(_vm.typeOptionsArr, function (e, i) {
                                    return _c("el-option", {
                                      key: i,
                                      attrs: { label: e, value: i },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "下次提醒日期:" } },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "datetime",
                                    placeholder: "选择日期",
                                    "picker-options": _vm.pickerOptions,
                                    "value-format": "timestamp",
                                  },
                                  model: {
                                    value: _vm.tempFollowup.next_time,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tempFollowup,
                                        "next_time",
                                        $$v
                                      )
                                    },
                                    expression: "tempFollowup.next_time",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "30px" },
                                attrs: { label: "附件:" },
                              },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    staticClass: "avatar-uploader",
                                    attrs: {
                                      "http-request": _vm.uploadSectionFile,
                                      "show-file-list": true,
                                      action: "action",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                        },
                                      },
                                      [_vm._v("点击上传")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c(
                      "div",
                      { staticStyle: { height: "400px", overflow: "auto" } },
                      [
                        _c("div", [_c("h4", [_vm._v("跟进记录")])]),
                        _vm._v(" "),
                        _vm.follows_up.length != 0
                          ? _c(
                              "el-timeline",
                              _vm._l(
                                _vm.follows_up,
                                function (activity, index) {
                                  return _c(
                                    "el-timeline-item",
                                    { key: index, attrs: { placement: "top" } },
                                    [
                                      _c(
                                        "el-card",
                                        {
                                          attrs: {
                                            "body-style": { padding: "15px" },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "g6 lh18" },
                                            [
                                              _c("span", [_vm._v("类型：")]),
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(activity.type) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "g6 lh18" },
                                            [
                                              _c("span", [_vm._v("主题 ：")]),
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(activity.subject) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          activity.file_info !== ""
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "g6 lh18 blueFont poi",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    附件 ：\n                                    "
                                                  ),
                                                  _c(
                                                    "span",
                                                    { staticClass: "blueFont" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          activity.file_info
                                                            .file_name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          activity.next_time
                                            ? _c(
                                                "div",
                                                { staticClass: "g6 lh18" },
                                                [
                                                  _vm._v(
                                                    "下次提醒时间 ：" +
                                                      _vm._s(activity.next_time)
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _c(
                              "h3",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v("暂无跟进记录")]
                            ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.followUpDialog = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addFollowUp()
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogShow
        ? _c("div", { staticClass: "drag" }, [
            _c(
              "div",
              {
                directives: [{ name: "drag", rawName: "v-drag" }],
                staticClass: "drag_box",
              },
              [
                _c("div", { staticClass: "title" }, [
                  _c("span", [_vm._v("来电提醒")]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "iconfont icon-cha",
                    on: { click: _vm.DialogClick },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "Main" }, [
                  _vm._v("\n                您有\n                "),
                  _c("span", [_vm._v(_vm._s(_vm.calls))]),
                  _vm._v(" "),
                  _c("span", [_vm._v("（" + _vm._s(_vm.MobileAddress) + "）")]),
                  _vm._v("的来电请您到客户列表电话条处接听\n            "),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }