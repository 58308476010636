import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Agentread, AgentFollow, addAgentFollow, Agentatgenjinfu } from '@/api/ceshi_xiugai2/myproject';
import { checkCustomerByMobile } from '@/api/updateUserInfo/users';
export default {
  data: function data() {
    return {
      calls: '',
      MobileAddress: '',
      dialogShow: false,
      info: {},
      imageUrl: '',
      imgYingye: '',
      srcList: [],
      logShowAll: false,
      log: [],
      logLength: 4,
      isusertype: '',
      typeOptionsArr: [],
      //新建类型
      follows_up: [],
      //跟进记录
      followUpDialog: false,
      //跟进开关
      tempFollowup: {
        aid: '',
        subject: '',
        type: '1',
        next_time: '',
        file_id: '',
        name: this.$route.query.title
      }
    };
  },
  mounted: function mounted() {
    var that = this;
    window.addEventListener('storage', function (item) {
      var names = localStorage.getItem('Incoming');
      if (names == 'ringingIb') {
        var call = localStorage.getItem('call');
        checkCustomerByMobile({
          mobile: call
        }).then(function (item) {
          var data = item.data;
          if (data.flag == 1) {
            that.calls = data.cname;
            that.MobileAddress = data.mobile_address;
          } else {
            that.calls = call;
            that.MobileAddress = data.mobile_address;
          }
          that.dialogShow = true;
        });
      }
      if (item.newValue != 'RINGING') {
        that.dialogShow = false;
        localStorage.setItem('Incoming', '');
      }
    });
  },
  created: function created() {
    //传来的ID
    this.getoneinfo();
    this.Getfollows_up();
  },
  computed: {},
  methods: {
    DialogClick: function DialogClick() {
      this.dialogShow = false;
    },
    getoneinfo: function getoneinfo() {
      var _this = this;
      Agentread({
        id: this.$route.query.id
      }).then(function (respomse) {
        _this.info = respomse.data;
        if (respomse.data.img_info.url == undefined) {
          _this.imageUrl = ' https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/a55c0a2be09adc0df6c564a7c4fbac6b.png';
        } else {
          _this.imageUrl = process.env.VUE_APP_BASE_API2 + respomse.data.img_info.url;
        }
        if (respomse.data.business_license_info) {
          _this.imgYingye = process.env.VUE_APP_BASE_API2 + respomse.data.business_license_info.url;
          _this.srcList.push(_this.imgYingye);
        }
      });
    },
    Getfollows_up: function Getfollows_up() {
      var _this2 = this;
      //跟进列表
      AgentFollow({
        aid: this.$route.query.id
      }).then(function (res) {
        _this2.follows_up = res.data.follow_list;
        _this2.typeOptionsArr = res.data.agent_follow_type;
      });
    },
    //新建跟进
    NewFollowUpRecord: function NewFollowUpRecord() {
      this.followUpDialog = true;
      this.tempFollowup.subject = '';
    },
    addFollowUp: function addFollowUp() {
      var _this3 = this;
      var id = this.$route.query.id;
      this.tempFollowup.aid = id;
      if (this.tempFollowup.type == '') {
        this.$message({
          type: 'warning',
          message: '请选择跟进类型'
        });
      } else if (this.tempFollowup.subject == '') {
        this.$message({
          type: 'warning',
          message: '请输入主题'
        });
      } else {
        addAgentFollow({
          aid: this.tempFollowup.aid,
          subject: this.tempFollowup.subject,
          type: this.tempFollowup.type,
          next_time: this.tempFollowup.next_time.toString().substr(0, 10),
          file_id: this.tempFollowup.file_id,
          name: this.tempFollowup.name
        }).then(function (res) {
          _this3.$notify({
            message: '提交成功',
            type: 'success'
          });
          _this3.followUpDialog = false;
          _this3.tempFollowup.next_time = '';
          _this3.tempFollowup.subject = '';
          _this3.$nextTick(function () {
            _this3.Getfollows_up();
          });
        });
      }
    },
    //跟进附件接口
    uploadSectionFile: function uploadSectionFile(param) {
      var _this4 = this;
      Agentatgenjinfu(param).then(function (res) {
        _this4.tempFollowup.file_id = res.data.id;
      });
    }
  }
};